/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ValidateUpdateprofilePractice } from "utilities/validator";
import {
  UpdateProfile,
  UploadProfilePicture,
} from "store/modules/accountSlice";
import MuiInput from "components/others/MuiInput";
import PhoneInput from "components/others/PhoneInput";
import Profile from "assets/images/profile.png";
import ImageUpload from 'pages/userpages/ImageUpload';
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import MuiDropdown from "components/others/MuiDropdown";
import { PRACTICE_COMPANIES, SECTOR } from "utilities/enumerations";
import "./style.scss";
import AddressInput from "components/others/AddressInput/AddressInput";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IsProcessing from "components/others/IsProcessing/IsProcessing";

const PracticeProfileEdit = ({ profileName, user_id = null }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { profile_data, user_token, profileImageName, a_is_processing } =
    useSelector((state) => state.account);
  const [formError, setFormError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profileImg, setProfileImg] = useState({
    imageBase64: "",
    imageName: "",
    imageType: "",
  });
  const [data, setData] = useState({
    full_name: "",
    appointment_time: "",
    unpaid_break: "",
    user_name: "",
    email: "",
    abn_number: "",
    mobile_number: "",
    sector: "",
    available_time: "",
    practice_name: "",
    practice_address: "",
    vaccination_status: "",
    pretest: "",
    profile: "",
    lat: "",
    lng: "",
    company: "",
    other_company: "",
    softwares: [],
    equipments: [],
    time_range: {},
  });

  //* For admin edits
  useEffect(() => {
    if (user_id) {
      setData({
        ...data,
        user_id: user_id,
      });
    }
  }, []);

  //* Set user fields on page load
  useEffect(() => {
    if (
      profile_data &&
      profile_data.basic_info &&
      profile_data.detailed_info
    ) {
      setData({
        ...data,
        ...profile_data.basic_info,
        ...profile_data.detailed_info,
      });
    }
  }, []);

  //* Check for error object
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm(true);
    }
  }, [formError]);

  //* Image upload
  useEffect(() => {
    if (profileImg.imageBase64 !== null && profileImg.imageBase64 !== "") {
      dispatch(
        UploadProfilePicture({
          user_token: user_token,
          imageData: profileImg,
        })
      );
    }
  }, [profileImg.imageBase64]);

  //* Image upload
  const imageUplaod = (imageList) => {
    setProfileImg({
      imageBase64: imageList[0].data_url,
      imageName: imageList[0].file.name,
      imageType: imageList[0].file.type,
    });
  };

  //* All inputs change event
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  //* Phone input change event
  const handlePhoneChange = (e) => {
    setData((preval) => {
      return {
        ...preval,
        mobile_number: e,
      };
    });
  };

  //* Submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateUpdateprofilePractice(data));
    setIsSubmitting(true);
  };

  //* Submit form
  const submitForm = () => {
    try {
      dispatch(UpdateProfile({ ...data, user_token: user_token }));
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };

  // * Location change
  const handlePlaceChange = (val) => {
    if (val) {
      setData((prevState) => ({
        ...prevState,
        lng: val.geometry.location.lng(),
        lat: val.geometry.location.lat(),
        practice_address: val.formatted_address,
      }));
    }
  };

  return (
    <div>
      <div className="practice_acc_details">
        {location.state && location.state.is_profile_incomplete && (
          <div className="alert alert-primary" role="alert">
            <FontAwesomeIcon className="" icon="fa-solid fa-circle-info" />
            &nbsp; Your account & profile is incomplete. Please fill all the
            fields with red asterisk (
            <span
              className="text-danger"
              style={{
                lineHeight: "12px",
                verticalAlign: "bottom",
                fontSize: "25px",
              }}
            >
              *
            </span>
            ).
          </div>
        )}
        <div className="form-group user_profile_img">
          <div className="img_container">
            {profileImageName !== null ? (
              <img
                alt="profile.png"
                src={
                  process.env.REACT_APP_API_PROFILE_IMAGES_URI +
                  profileImageName
                }
              ></img>
            ) : (
              <img alt="profile.png" src={Profile}></img>
            )}
          </div>
          <ImageUpload imageUplaod={imageUplaod}></ImageUpload>
        </div>
        {!a_is_processing ? (
          <div className="row">
            <div className="col-lg-12 m-auto">
              {/* <h3 className="text text-black">
                {profileName && profileName !== ""
                  ? profileName + " Account"
                  : "Practice Account"}
              </h3> */}
              <hr></hr>
              <form onSubmit={handleSubmit}>
                {/* Username, email */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiInput
                      isRequired={true}
                      label="Username"
                      id="userName"
                      placeholder="Enter Username"
                      inputError={formError.user_name}
                      inputValue={data.user_name}
                      type="text"
                      handleChange={handleChange}
                      name="user_name"
                      disabled={true}
                    ></MuiInput>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiInput
                      isRequired={true}
                      label="Email"
                      id="userEmail"
                      placeholder="Enter your Email"
                      inputError={formError.email}
                      inputValue={data.email}
                      type="text"
                      handleChange={handleChange}
                      name="email"
                    ></MuiInput>
                  </div>
                </div>

                {/* ABN No, Mobile No */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiInput
                      isRequired={true}
                      label="ABN"
                      id="abnNumber"
                      placeholder="Enter your abn number"
                      inputError={formError.abn_number}
                      inputValue={data.abn_number}
                      type="text"
                      handleChange={handleChange}
                      name="abn_number"
                    ></MuiInput>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <PhoneInput
                      isRequired={true}
                      label="Mobile Number"
                      name="mobile_number"
                      inputError={formError.mobile_number}
                      inputValue={data.mobile_number}
                      handlePhoneChange={handlePhoneChange}
                    />
                  </div>
                </div>

                {/* Sector, Practice Name */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiDropdown
                      isRequired={true}
                      label={"Sector"}
                      id={"sector"}
                      name={"sector"}
                      inputValue={data.sector}
                      inputError={formError.sector}
                      handleChange={handleChange}
                      options={SECTOR}
                    ></MuiDropdown>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiInput
                      label="Practice Name"
                      id="practiceName"
                      placeholder="Enter practice name"
                      inputError={formError.practice_name}
                      inputValue={data.practice_name}
                      type="text"
                      handleChange={handleChange}
                      name="practice_name"
                      showInfoText={true}
                    ></MuiInput>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiDropdown
                      isRequired={true}
                      label={"Company"}
                      id={"company"}
                      name={"company"}
                      inputValue={data.company}
                      inputError={formError.company}
                      handleChange={handleChange}
                      options={PRACTICE_COMPANIES}
                    ></MuiDropdown>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <AddressInput
                      label="Address"
                      id="practice_address"
                      inputError={formError.practice_address}
                      type="text"
                      handlePlaceChange={handlePlaceChange}
                      name="practice_address"
                      inputValue={data.practice_address}
                      handleChange={handleChange}
                    ></AddressInput>
                  </div>
                </div>
                {data.company === "other" && (
                  <div className="row mt-3">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <MuiInput
                        label="Other company"
                        id="other_company"
                        placeholder="Enter other compnay name"
                        inputError={formError.other_company}
                        inputValue={data.other_company}
                        type="text"
                        handleChange={handleChange}
                        name="other_company"
                      ></MuiInput>
                    </div>
                  </div>
                )}

                <div className="form-group mt-4 mb-2">
                  <Button
                    type="submit"
                    sx={{
                      width: 200,
                      height: 45,
                      backgroundColor: "#4493c5",
                      ":hover": { backgroundColor: "#4493c5" },
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveAltIcon />}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <IsProcessing></IsProcessing>
        )}
      </div>
    </div>
  );
};

export default PracticeProfileEdit;
