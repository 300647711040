import { Divider } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import { ROLES } from "../../utilities/enumerations";
import { useSelector } from "react-redux";

const MemberStatusCard = () => {
  const { is_logged_in, role } = useSelector((state) => state.account);
  const { subscription_lookup_data } = useSelector((state) => state.subscription);
  // const subscription = useSelector((state) => state.subscription);
  // const location = useLocation();
  // const [subData, setSubData] = useState({});

  // useEffect(() => {
  //   if (subscription.subscription_lookup_data) {
  //     setSubData({ ...subscription.subscription_lookup_data });
  //   }
  // }, []);

  // useEffect(() => {
  //   setSubData({ ...subscription.subscription_lookup_data });
  // }, [subscription.subscription_lookup_data]);

  return (
    <>
      {is_logged_in && role === ROLES.practice && (
        <>
          <div className="membership_info">
            {subscription_lookup_data.is_member && parseInt(subscription_lookup_data.is_member) === 1 && subscription_lookup_data.status !== 'cancelled' ? (
              <small className="d-block">LocumsOnly member.</small>
            ) : (
              <small className="d-block">Not a LocumsOnly member.</small>
            )}

            {/* {subData.is_member && parseInt(subData.is_member) === 1 ? (
              <small className="d-block">
                Subscription type : {CAPITALIZE(subData.type)}
              </small>
            ) : (
              <small className="d-block">
                Become a LocumsOnly&nbsp;
                <NavLink
                  to="/subscription_checkout"
                  state={{ from: location.pathname }}
                >
                  member
                </NavLink>
              </small>
            )}
            {subData.is_member && parseInt(subData.is_member) === 1 && (
              <>
                <small className="d-block">
                  Start date :{" "}
                  {subData.subscription_start_date &&
                    dayjs(subData.subscription_start_date).format("DD/MM/YYYY")}
                </small>
                <small className="d-block">
                  End date :{" "}
                  {subData.subscription_end_date &&
                    dayjs(subData.subscription_end_date).format("DD/MM/YYYY")}
                </small>
              </>
            )}
            {subData.is_free_trial_expired === 1 && (
              <>
                <small className="d-block text-danger">
                  {subData.is_free_trial_expired && "Free-trial expired"}
                </small>
              </>
            )} */}
            {parseInt(subscription_lookup_data.is_member) === 1 && subscription_lookup_data.status !== 'cancelled' ? (
              <NavLink
                to={"/" + ROLES[role] + "/manage_subscriptions"}
              // reloadDocument={true}
              >
                Manage subscription
              </NavLink>
            ) : (
              <NavLink to={"/subscription_checkout"} reloadDocument={true}>
                Become a member
              </NavLink>
            )}
          </div>
          <Divider></Divider>
        </>
      )}
    </>
  );
};

export default MemberStatusCard;
