import React, { useEffect, useState } from "react";
import { Dropdown } from "@nextui-org/react";
import { Badge, Avatar, Dropdown as AntDropdown, Button } from "antd";
import { Logout as logoutUser, ReadNotification, UserNotifications, ReadAllNotifications } from "../../store/modules/accountSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../assets/images/logo_main.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ROLES } from "../../utilities/enumerations.js";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import Timezone from "dayjs/plugin/timezone";
import Utc from "dayjs/plugin/utc";
import { Navbar, Offcanvas, Container, Nav } from 'react-bootstrap'
import { NOTIFICATION_PARAMS } from "../../utilities/helper.js";
import { faCircle, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

dayjs.extend(RelativeTime);
dayjs.extend(Timezone);
dayjs.extend(Utc);

const PrimaryNavbar = ({ is_dashboard = false }) => {
  const navigate = useNavigate();
  const [url, setUrl] = useState(null);
  const [expand] = useState('lg')
  const location = useLocation();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  const newNotificationStyles = {
    height: "$38",
    margin: "$3",
    marginTop: "$6",
    marginBottom: "$6",
    position: "relative",
    paddingTop: "$2",
    paddingBottom: "$2",
    boxShadow: "#4493c5 0px 0px 0px 3px",
    borderRadius: "10px",
    border: "1px solid #4493c5",
    padding: "10px",
  };

  const notificationStyles = {
    height: "$38",
    margin: "$3",
    boxShadow: "$sm",
    position: "relative",
    paddingTop: "$2",
    paddingBottom: "$2",
  };

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    if (
      account.is_logged_in &&
      account.user_token &&
      account.user_token !== "" &&
      account.role &&
      account.role !== "" &&
      account.role !== ROLES.admin
    ) {
      const interval = setInterval(() => {
        if (account.is_logged_in && account.userId !== "") {
          dispatch(UserNotifications({ user_token: account.user_token }));
        }
      }, 20000);
      return () => clearInterval(interval);
    }
  }, [account.is_logged_in, account.role, account.userId, account.user_token, dispatch]);

  const handleNotificationCick = (val) => {
    if (parseInt(val.is_read) === 0) {
      dispatch(ReadNotification({ id: val.id, user_token: account.user_token }));
    }
    const { path, tab } = NOTIFICATION_PARAMS(account.role, val.type);
    navigate(
      `/${account.role}/${path}`,
      {
        state: {
          tab_key: tab,
        },
      },
      false
    );
  };

  const handleMarkAllRead = () => {
    dispatch(ReadAllNotifications({ user_token: account.user_token }));
  };

  return (
    <Navbar fixed="top" key={expand} expand={expand} className="bg-body-tertiary mb-3 custom_navbar">
      <Container fluid={is_dashboard ? true : 'lg'}>
        <Navbar.Toggle style={{ borderColor: "transparent", padding: "0px" }} aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Navbar.Brand >
          <NavLink className="navbar-brand" to="/">
            <img src={Logo} alt="logo"></img>
          </NavLink></Navbar.Brand>
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              Offcanvas
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav>
              <NavLink
                className={
                  "nav-link nav-link-custom" +
                  (url === "/post_availabilities" ? " activeLinkColor" : "")
                }
                to="/post_availabilities"
              >
                Post Availability
              </NavLink>
              <NavLink
                className={
                  "nav-link nav-link-custom" +
                  (url === "/browse_availabilities" ? " activeLinkColor" : "")
                }
                to="/browse_availabilities"
                reloadDocument={true}
              >
                Browse Availability
              </NavLink>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <Nav className="justify-content-end align-items-center flex-row flex-grow-1">
          <AntDropdown trigger={["hover"]} arrow menu={{
            items: [
              { key: '1', label: (<NavLink className="nav-link" to={"/about_us"}><Button block>About Us</Button></NavLink>), },
              { key: '2', label: (<NavLink className="nav-link" to={"/faq"}><Button block>Faq's</Button></NavLink>), },
              { key: '3', label: (<NavLink className="nav-link" to={"/help"}><Button block>Help</Button></NavLink>), },
              {
                key: '4', label: (<NavLink className="nav-link" to="" onClick={() => {
                  const element = document.getElementById("contact_us_sec");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                }}><Button block>Contact Us</Button></NavLink>),
              },
              { key: '5', label: (<NavLink className="nav-link" to={"/how-it-works"}><Button block>How it works?</Button></NavLink>), },
            ]
          }} align={{ offset: [0, 20] }} overlayClassName="user_menu" placement="bottomCenter">
            <Avatar className="me-2" size="default" style={{ backgroundColor: "lightgray", display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon icon="fa-solid fa-circle-info" style={{ fontSize: "20px", color: "black" }} />
            </Avatar>
          </AntDropdown>
          {!account.is_logged_in && (
            <>
              <Button onClick={() => { navigate("login") }}  >Login</Button>
              <Button className="ms-2" onClick={() => { navigate("signup") }}  >Sign up</Button>
            </>
          )}
          {account.is_logged_in && (
            <>
              {account.user_chat_id && account.user_chat_token && (
                <Badge offset={[-15, 8]} count={<FontAwesomeIcon color={account.new_message ? "green" : "transparent"} icon={faCircle} style={{ fontSize: "10px" }} />} >
                  <Avatar className="me-2" size="default" style={{ backgroundColor: "lightgray", display: "flex", alignItems: "center" }} onClick={() => { navigate(`/${ROLES[account.role]}/chat`); }}>
                    <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: "20px", color: "#4493c5" }} />
                  </Avatar>
                </Badge>
              )}
              <Dropdown placement="bottom-right" closeOnSelect={false}>
                <Dropdown.Trigger flat="false">
                  <Badge count={parseInt(account.user_notifications_count)} offset={[-15, 5]} style={{ backgroundColor: '#4493c5' }}>
                    <Avatar className="me-2" size="default" style={{ backgroundColor: "lightgray", display: "flex", alignItems: "center" }}>
                      <FontAwesomeIcon icon="fa-solid fa-bell" style={{ fontSize: "20px", color: "#4493c5" }} />
                    </Avatar>
                  </Badge>
                </Dropdown.Trigger>
                <Dropdown.Menu
                  aria-label="Static Actions"
                  css={{
                    minWidth: 300,
                    backgroundColor: "White",
                    position: "relative",
                  }}
                >
                  {account.userNotifications.length === 0 && (
                    <Dropdown.Item
                      key="profile"
                      css={{ height: "$18" }}
                      textValue="No notifictions available"
                    >
                      <li className="p-2 text-cener">
                        <p className="m-0 text-center">
                          No notifictions available
                        </p>
                      </li>
                    </Dropdown.Item>
                  )}
                  {account.userNotifications &&
                    account.userNotifications.length > 0 && (
                      <Dropdown.Item key="profile" css={{ height: "$18" }} textValue="Mark all read">
                        <Button block type="primary" onClick={() => handleMarkAllRead()}>Mark all read</Button>
                      </Dropdown.Item>
                    )}
                  {account.userNotifications &&
                    account.userNotifications.length > 0 &&
                    account.userNotifications.map((item) => {
                      return (
                        <Dropdown.Item
                          textValue={item.message}
                          key={item.id + Math.random()}
                          classNames={
                            item.is_read && parseInt(item.is_read) === 0
                              ? "border_highlight"
                              : ""
                          }
                          css={
                            item.is_read == 0
                              ? newNotificationStyles
                              : notificationStyles
                          }
                        >
                          <div
                            className="notification-content"
                            onClick={() =>
                              handleNotificationCick({
                                type: item.type,
                                id: item.id,
                                is_read: item.is_read,
                              })
                            }
                          >
                            <div className="notification_icon_div">
                              <div className="">
                                <Avatar size="default" style={{ backgroundColor: '#4493c5', color: '#fff' }}>H</Avatar>
                              </div>
                              <div className="d-flex align-items-center">
                                <p className="m-0">{item.message}</p>
                              </div>
                            </div>
                            <hr className="m-0 p-0 "></hr>
                            <div className="mt-1 d-flex justify-content-between align-items-center">
                              <small className="text-end text-capitalize mx-2">
                                {dayjs(item.created_at)
                                  .tz(dayjs.tz.guess())
                                  .fromNow(true)}
                                &nbsp;ago
                              </small>
                            </div>
                          </div>
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>

              <AntDropdown trigger={["hover"]} arrow menu={{
                items: [
                  { key: '1', label: (<Button type="text" block>Signed in as {account.userName}</Button>), },
                  {
                    key: '2', label: (<Button block onClick={() => { account.role === ROLES.admin ? navigate("/" + ROLES[account.role]) : navigate("/" + ROLES[account.role] + "/bookings"); }}>Dashboard</Button>),
                  },
                  { type: "divider" },
                  { key: '3', label: (<Button danger type="primary" block onClick={() => { dispatch(logoutUser(account.user_token)); }}>Logout</Button>), },
                ]
              }} align={{ offset: [0, 20] }} overlayClassName="user_menu" placement="bottomRight">
                {account.profileImageName &&
                  account.profileImageName !== "" ? (
                  <Avatar size="default" style={{ backgroundColor: '#4493c5', color: '#fff' }} src={process.env.REACT_APP_API_PROFILE_IMAGES_URI + account.profileImageName}></Avatar>
                ) : (
                  <Avatar size="default" style={{ backgroundColor: '#4493c5', color: '#fff' }}>{account.userName ? account.userName.charAt(0).toLocaleUpperCase() : ""}</Avatar>
                )}

              </AntDropdown>
            </>
          )}
        </Nav>
      </Container>
    </Navbar >
  );
};

export default PrimaryNavbar;
