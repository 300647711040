import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import QUESTIONBRO from '../../assets/images/questions-bro.png'
import LOCUM from '../../assets/images/locum.png'
import PRACTICE from '../../assets/images/practice.png'
import COST from '../../assets/images/cost.png'
import COMMUNICATION from '../../assets/images/communication.png'
import { Button } from 'antd'
import './style.scss'
import { NavLink } from 'react-router-dom'

const HowItWorks = () => {
    return (
        <section className='how_it_works'>
            <Container fluid='lg'>
                <Row className='banner'>
                    <Col xs={{ span: 12, order: "last" }} md={{ span: 6, order: "first" }}>
                        <h3>How It works?</h3>
                        <p>Post your availability, get offers, accept offers you wand, get paid!</p>
                        <NavLink to="/post_availabilities">
                            <Button shape="round">Post your availability as Locum (No Cost)</Button>
                        </NavLink>
                    </Col>
                    <Col xs={{ span: 12, order: "first" }} md={6} className="banner_img">
                        <img src={QUESTIONBRO} alt='how it works'></img>
                    </Col>
                </Row>

                <Row className='locum'>
                    <Col xs={12} md={6}>
                        <div className='locum_header'>
                            <h3>For Locums</h3>
                            <p>Post your availability. Sit back. Relax. Wait for jobs to come to you.</p>
                            <NavLink to="/post_availabilities">
                                <Button type="primary" shape="round">Post your availability as Locum (No Cost)</Button>
                            </NavLink>
                        </div>

                        <ol>
                            <li>
                                <b>Complete your profile</b>
                                <p>Head to your dashboard and fill in the profile, including your normal working hours, charge rate, companies you like to work with and the distance you are happy to travel.</p>
                            </li>
                            <li>
                                <b>Select the dates that you want to work</b>
                                <p>Go to "Post Availability" and highlight the dates that you would like to work. Practices within your traveling distance will be able to find you based on your availability.</p>
                            </li>
                            <li>
                                <b>Receive requests. Select the most suitable practice</b>
                                <p>Go to "My Booking Request" and review the profiles, ratings and completed jobs of the inviting practices then select the practice you would like to work with.</p>
                            </li>
                            <li>
                                <b>Send invoices</b>
                                <p>Once your shifts finish, simply send your invoices to the practice via your email.</p>
                            </li>
                        </ol>
                    </Col>
                    <Col xs={{ span: 12, order: "first" }} md={6} className="locum_img">
                        <img src={LOCUM} alt='how it works'></img>
                    </Col>
                </Row>

                <Row className='practice'>
                    <Col xs={{ span: 12, order: "last" }} md={{ span: 6, order: "first" }}>
                        <div className='locum_header'>
                            <h3>For Practices</h3>
                            <p>No more asking who's available one at a time. Cut the frustration. Enter the dates you need and see who can help.</p>
                            <NavLink to="/browse_availabilities">
                                <Button type="primary" shape="round">Browse availability</Button>
                            </NavLink>
                        </div>

                        <ol>
                            <li>
                                <h3>Complete your profile</h3>
                                <p>Head to your dashboard and fill in the profile, including your practice testing hours, appointment time, software and equipment.</p>
                            </li>
                            <li>
                                <h3>Select the dates you want locum cover</h3>
                                <p>Go to "Browse Availability" and highlight the dates that you would like to have locum cover. Locums who are available on those dates would appear on your selection screen.</p>
                            </li>
                            <li>
                                <h3>Select the most suitable locum. Send booking request</h3>
                                <p>Review the profiles, ratings, and completed jobs of the available locums. Select the most suitable locum and send your booking request.</p>
                            </li>
                            <li>
                                <h3>Processing invoices</h3>
                                <p>Once the shifts finish, please provide your email to the locum to send the invoice.</p>
                            </li>
                        </ol>
                    </Col>
                    <Col xs={{ span: 12, order: "first" }} md={6} className="practice_img">
                        <img src={PRACTICE} alt='how it works'></img>
                    </Col>
                </Row>


                <Row className='cost'>
                    <Col xs={12} md={6} className="costs_img">
                        <img src={COST} alt='how it works'></img>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className='locum_header'>
                            <h3>How much does it cost?</h3>
                            <p>We charge a low cost monthly fee of $49 from Practices, and can be cancelled at anytime. There is no charge to locums. There is no limit to the number of bookings made via the platform. </p>
                        </div>
                    </Col>
                </Row>


                <Row className='communication'>
                    <Col xs={12} md={6} className="communication_img">
                        <img src={COMMUNICATION} alt='how it works'></img>
                    </Col>
                    <Col xs={{ span: 12, order: "last" }} md={{ span: 6, order: "first" }}>
                        <div className='locum_header'>
                            <h3>Communications</h3>
                            <p>Use LocumsOnly's Messages system to connect from the get go. You can ask more in-depth questions about the locum or practice before confirming the booking, or before making any changes.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HowItWorks
