/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./style.scss";
import { GetLocumBookingRequestes } from "../../../store/modules/bookingSlice";
import { useDispatch, useSelector } from "react-redux";
import BookingRequestCard from "../../../components/cards/BookingRequestCard";
import { Col, Row } from "antd";
import IsProcessing from "../../../components/others/IsProcessing/IsProcessing";

const ReviewBookingRequests = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { locum_booking_requests, a_is_processing } = useSelector(
    (state) => state.bookings
  );

  // * Get booking requests
  useEffect(() => {
    dispatch(
      GetLocumBookingRequestes({
        user_token: account.user_token,
        show_loader: true,
      })
    );
  }, []);

  return (
    <Row className="review_booking_requests row m-0">
      {!a_is_processing ? (
        <Col span={24}>
          {locum_booking_requests && locum_booking_requests.length > 0 ? (
            <Row gutter={[20, 10]}>
              {locum_booking_requests.map((item, index) => {
                return (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <BookingRequestCard
                      booking_id={item.id}
                      practice_id={item.practice_id}
                      practice_user_name={item.user_name}
                      practice_lat={item.practice_lat}
                      practice_lng={item.practice_lng}
                      index={index}
                      practice_full_Name={item.practice_name}
                      requested_dates={item.requested_dates}
                      practice_time_range={item.time_range}
                    ></BookingRequestCard>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <div className="no_requests">
              <p>No requests found.</p>
            </div>
          )}
        </Col>
      ) : (
        <IsProcessing></IsProcessing>
      )}
    </Row>
  );
};

export default ReviewBookingRequests;
