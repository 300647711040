import React from "react";
import { Col, Divider, Result, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useLocation } from "react-router-dom";
import dayjs from "dayjs";

const Step3 = ({
  showInfoAlert,
  infoAlertMsg,
  showProfileIncomplete,
  selected_dates_save,
  showNotAMemberError,
  role,
}) => {
  const location = useLocation();
  return (
    <Row>
      <Col span={24}>
        <h4>Preview Selected Dates</h4>
        {showInfoAlert && (
          <div className="alert alert-primary mb-5" role="alert">
            <FontAwesomeIcon className="" icon="fa-solid fa-circle-info" />
            &nbsp; {infoAlertMsg}
          </div>
        )}
        {showProfileIncomplete && (
          <div className="alert alert-warning mb-5" role="alert">
            <FontAwesomeIcon className="" icon="fa-solid fa-circle-info" />
            &nbsp; Your account & profile is incomplete.&nbsp;
            <NavLink to={`/${role}`} state={{ is_profile_incomplete: true }}>
              Click here
            </NavLink>
            &nbsp; to complete your account and profile.
          </div>
        )}
        {showNotAMemberError && (
          <div className="alert alert-warning mb-5" role="alert">
            <FontAwesomeIcon className="" icon="fa-solid fa-circle-info" />
            &nbsp; You are not a mmeber of LocumsOnly.&nbsp;
            <NavLink
              to={`/subscription_checkout`}
              state={{
                not_a_member: true,
                from: location.pathname,
              }}
            >
              Click here
            </NavLink>
            &nbsp; to purchase <b>subscription</b> or start a <b>free trial</b>.
          </div>
        )}
        <Divider></Divider>
        <Row
          style={{
            maxHeight: "300px",
            overflowY: "scroll",
          }}
        >
          <Col>
            {selected_dates_save &&
              selected_dates_save.length > 0 &&
              selected_dates_save.map((item, index) => {
                return (
                  <p key={index}>
                    {
                      // index +
                      // 1 +
                      // ": " +
                      dayjs(item).format("DD/MM/YYYY") +
                        " " +
                        dayjs(item).format("dddd") +
                        " " +
                        ""
                      // GET_TIME_OF_DAY(
                      //   DAYS_ABBR[dayjs(item).get("day")],
                      //   JSON.parse(timeRange)
                      // )
                    }
                  </p>
                );
              })}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Step3;
