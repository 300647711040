import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ForgotPassword as forgotPassword,
  SetAccountSliceBits,
} from "../../store/modules/accountSlice";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../components/others/Input";
import { ValidateForgotpassword } from "../../utilities/validator";
import "./style.scss";
import { GET_IMAGE } from "../../utilities/helper";

const ForgotPassword = () => {
  const account = useSelector((state) => state.account);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState({});
  const [state, setstate] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [data, setData] = useState({
    email: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    document.title = "LocumsOnly | Forgot password";
  }, []);

  useEffect(() => {
    if (account.isEmailSent && state) {
      data.email = "";
      setIsEmailSent(true);
      setstate(false);
      dispatch(
        SetAccountSliceBits({
          bitToSet: "isEmailSent",
          value: false,
        })
      );
    }
  }, [account.isEmailSent]);

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formError]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateForgotpassword(data));
    setIsSubmitting(true);
  };

  const submitForm = () => {
    try {
      dispatch(forgotPassword(data));
      setstate(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      <div className="row m-0 forgot_password">
        <form onSubmit={handleSubmit}>
          <div className="col-lg-6 m-auto forgot_password_card">
            {!isEmailSent && (
              <NavLink onClick={goBack}>
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-left"
                  style={{ color: "#4493c5" }}
                />
              </NavLink>
            )}
            <div className="text-center mt-3 mb-3">
              <img
                src={
                  isEmailSent ? GET_IMAGE("correct.png") : GET_IMAGE("lock.png")
                }
                className=""
                alt="lock.png"
              ></img>
              {isEmailSent ? (
                <p className="mt-5">
                  Please check your email to reset your password
                </p>
              ) : (
                <h4 className="mt-3">Forgotten your password?</h4>
              )}
            </div>
            {isEmailSent ? (
              <div>
                <NavLink
                  to="/"
                  className="btn btn-success btn-link m-auto d-block  mt-5 mb-3"
                >
                  Home
                </NavLink>
              </div>
            ) : (
              <div>
                <Input
                  label="Email"
                  bold={false}
                  id="email"
                  placeholder="abc@gmail.com"
                  inputError={formError.email}
                  inputValue={data.email}
                  type="text"
                  handleChange={handleChange}
                  name="email"
                ></Input>
                <div className="form-group mt-4">
                  <button
                    className="btn btn-success d-block w-100 btn-submit"
                    type="submit"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
