/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  CancelStripeSubscription,
  GetSubscriptionDetails,
  SetSubscriptionSliceBits,
} from "../../../store/modules/subscriptionSlice";
import { Button, Col, Result, Row, Tabs } from "antd";
import PaymentMethodCard from "../../../components/cards/PaymentMethodCard";
import {
  GetPracticePaymentMethods,
  GetPracticeStripePayments,
  SetPaymentSliceBits,
} from "../../../store/modules/paymentSlice";
import AntDesignButton from "../../../components/others/Button/AntDesignButton";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { confirmAlert } from "react-confirm-alert";
import { ROLES } from "../../../utilities/enumerations";
import IsProcessing from "../../../components/others/IsProcessing/IsProcessing";
import PracticePaymentsTable from "../../../components/tables/PracticePaymentsTable";
import { UpdateSubscriptionLookupData } from "store/modules/accountSlice";

const ManageSubscriptions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const [CurrentTab, setCurrentTab] = useState(null);
  const [paymentMethodUpdateSuccess, setPaymentMethodUpdateSuccess] =
    useState(false);
  const {
    sc_is_processing,
    sub_cancel_success,
    subscription_lookup_data,
  } = useSelector((state) => state.subscription);

  const {
    p_is_processing,
    payment_method_change_success,
    customer_payment_methods,
    payment_method_delete_success,
  } = useSelector((state) => state.payment);

  // This `useEffect` will fetch and load the user's subscription details when the component is mounted.
  useEffect(() => {
    dispatch(
      GetSubscriptionDetails({
        user_token: account.user_token,
        show_loader: true,
      })
    );
  }, []);

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    switch (tab) {
      case "manage_subscription":
        dispatch(
          GetSubscriptionDetails({
            user_token: account.user_token,
            show_loader: true,
          })
        );
        break;
      case "payment_methods":
        dispatch(
          GetPracticePaymentMethods({
            user_token: account.user_token,
            show_loader: true,
          })
        );
        break;
      case "stripe_payments":
        dispatch(
          GetPracticeStripePayments({
            user_token: account.user_token,
            show_loader: true,
          })
        );
        break;

      default:
        dispatch(
          GetSubscriptionDetails({
            user_token: account.user_token,
            show_loader: true,
          })
        );
        break;
    }
  };

  // This `useEffect` is triggered whenever the `payment_method_change_success` value changes.
  useEffect(() => {
    // Check if the payment method change was successful.
    if (payment_method_change_success) {
      // If successful, set the `paymentMethodUpdateSuccess` state to true.
      setPaymentMethodUpdateSuccess(true);
      // Dispatch an action to set `payment_method_change_success` to false,
      // presumably to reset it for future use or to acknowledge the success.
      dispatch(
        SetPaymentSliceBits({
          bitToSet: "payment_method_change_success",
          value: false,
        })
      );
    }
  }, [payment_method_change_success]);

  useEffect(() => {
    if (sub_cancel_success) {
      SetSubscriptionSliceBits({
        bitToSet: "sub_cancel_success",
        value: false,
      })
      dispatch(UpdateSubscriptionLookupData({
        data: subscription_lookup_data
      }))
    }
  }, [sub_cancel_success])


  // This `useEffect` is triggered whenever the `payment_method_delete_success` value changes.
  useEffect(() => {
    // Check if the payment method deletion was successful.
    if (payment_method_delete_success) {
      // If successful, set the `paymentMethodUpdateSuccess` state to true.
      setPaymentMethodUpdateSuccess(true);

      // Dispatch an action to set `payment_method_delete_success` to false,
      // presumably to reset it for future use or to acknowledge the success.
      dispatch(
        SetPaymentSliceBits({
          bitToSet: "payment_method_delete_success",
          value: false,
        })
      );
    }
  }, [payment_method_delete_success]);

  // This function is used to handle the cancellation of a subscription.
  const handleCancelSubscription = (subscription_id) => {
    // Display a confirmation dialog to confirm subscription cancellation.

    confirmAlert({
      // Message displayed in the confirmation dialog.
      message: "Are you sure you want to cancel your subscription?",
      buttons: [
        {
          // Yes button: If clicked, this will trigger the cancellation of the subscription.
          label: "Yes",
          onClick: () =>
            dispatch(
              CancelStripeSubscription({
                // Dispatch a cancellation action with user token and subscription ID.
                user_token: account.user_token,
                subscription_id: subscription_id,
              })
            ),
        },
        {
          // No button: If clicked, the cancellation is not executed.
          label: "No",
        },
      ],
    });
  };

  return (
    <Row className="manage_subscriptions">
      {paymentMethodUpdateSuccess ? (
        <Col span={24}>
          <Result
            status="success"
            subTitle="Your payment method settings updated successfully"
            extra={[
              <NavLink
                reloadDocument={true}
                to={`/${ROLES[account.role]}/manage_subscriptions`}
              >
                <Button>Continue</Button>
              </NavLink>,
            ]}
          />
        </Col>
      ) : (
        <Col span={24}>
          <h5>Subscription/Payment methods</h5>
          <hr></hr>
          <Tabs
            size="large"
            defaultActiveKey={"manage_subscription"}
            defaultValue={"manage_subscription"}
            type="card"
            onChange={(val) => {
              handleTabChange(val);
            }}
          >
            <Tabs.TabPane
              tab="Manage Subscription"
              tabKey="manage_subscription"
              key="manage_subscription"
            >
              <Row>
                {sc_is_processing ? (
                  <IsProcessing />
                ) : (
                  <Col span={24}>
                    {subscription_lookup_data &&
                      parseInt(subscription_lookup_data.is_member) === 1 && (
                        <Row>
                          <Col span={24} className="subscription_details">
                            <div>
                              <b>LocumsOnly member : </b>
                              <p>
                                {parseInt(subscription_lookup_data.is_member) === 1 && subscription_lookup_data.status !== 'cancelled'
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                            <div>
                              <b>Subscription type : </b>
                              <p>
                                {parseInt(
                                  subscription_lookup_data.is_free_trial_active
                                ) === 1
                                  ? "Trial"
                                  : "Monthly basic"}
                              </p>
                            </div>
                            {subscription_lookup_data &&
                              parseInt(
                                subscription_lookup_data.is_free_trial_active
                              ) === 1 ? (
                              <>
                                <div>
                                  <b>Free trial start date : </b>
                                  <p>
                                    {dayjs(
                                      subscription_lookup_data.free_trial_start_date
                                    ).format("DD/MM/YYYY")}
                                  </p>
                                </div>
                                <div>
                                  <b>Free trial end date : </b>
                                  <p>
                                    {dayjs(
                                      subscription_lookup_data.free_trial_end_date
                                    ).format("DD/MM/YYYY")}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <b>Subscription start date : </b>
                                  <p>
                                    {dayjs(
                                      subscription_lookup_data.subscription_start_date
                                    ).format("DD/MM/YYYY")}
                                  </p>
                                </div>
                                <div>
                                  <b>Subscription end date : </b>
                                  <p>
                                    {dayjs(
                                      subscription_lookup_data.subscription_end_date
                                    ).format("DD/MM/YYYY")}
                                  </p>
                                </div>
                              </>
                            )}
                            <div>
                              <b></b>
                              <p></p>
                            </div>
                          </Col>
                        </Row>
                      )}
                    <Row gutter={[10, 10]} style={{ marginTop: "20px" }}>
                      <Col>
                        <AntDesignButton
                          btn_label="Add payment method"
                          handleClick={() => {
                            navigate(
                              "/add_payment_method",
                              {
                                state: {
                                  from: location.pathname,
                                },
                              },
                              true
                            );
                          }}
                        ></AntDesignButton>
                      </Col>
                      <Col>
                        {subscription_lookup_data &&
                          parseInt(subscription_lookup_data.is_member) ===
                          1 && (
                            <AntDesignButton
                              danger={true}
                              btn_label="Cancel subscription"
                              handleClick={() => {
                                handleCancelSubscription(
                                  subscription_lookup_data.stripe_subscription_id
                                );
                              }}
                            ></AntDesignButton>
                          )}
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Tabs.TabPane>

            {/* Payment methods */}
            <Tabs.TabPane
              tab="Payment Methods"
              tabKey="payment_methods"
              key="payment_methods"
            >
              <Row gutter={[10, 0]} justify="start">
                {p_is_processing ? (
                  <IsProcessing />
                ) : (
                  <>
                    {customer_payment_methods &&
                      customer_payment_methods.length > 0 ? (
                      customer_payment_methods.map((item, index) => {
                        return (
                          <Col
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={6}
                            key={index}
                          >
                            <PaymentMethodCard
                              payment_method_id={item.payment_method_id}
                              stripe_customer_id={item.stripe_customer_id}
                              key={index}
                              issuer={item.brand}
                              is_default={item.is_default}
                              cvc="xxx"
                              expiry={
                                item.exp_month.length > 1
                                  ? item.exp_month + "/" + item.exp_year
                                  : "0" + item.exp_month + "/" + item.exp_year
                              }
                              name={item.name ? item.name : "N/A"}
                              number={`************${item.last_four}`}
                            ></PaymentMethodCard>
                          </Col>
                        );
                      })
                    ) : (
                      <Col span={24}>
                        <p style={{ marginTop: "20px", textAlign: "center" }}>
                          No payment methods found.
                        </p>
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Stripe payments"
              tabKey="stripe_payments"
              key="stripe_payments"
            >
              <Row>
                {p_is_processing ? (
                  <IsProcessing />
                ) : (
                  <Col span={24}>
                    <PracticePaymentsTable current_tab={CurrentTab} />
                  </Col>
                )}
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      )}
    </Row>
  );
};

export default ManageSubscriptions;
