/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Divider, Result, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateFilters from "./DateFilters";
import { GetFilteredLocums, SendBookingRequest, SetAvailabilitySliceBits } from "store/modules/bookingSlice";
import AntDesignButton from "components/others/Button/AntDesignButton";
import DatePicker from "react-datepicker";
import { ResetBrowseSteps, SetBrowseSliceSate, SetDatesSave, SetDatesShow, SetSteps } from "store/modules/browseStepsSlice";
import { ROLES, SORT_OPTIONS } from "utilities/enumerations";
import LocumCard from "components/cards/LocumCard";
import { GetFilteredLocumInfoAndDates } from "store/modules/userSlice";
import { Segmented } from 'antd';
import { FIX_TIMEZONE_OFFSET } from "utilities/helper";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import MuiDropdown from "components/others/MuiDropdown";
import Step3 from "./Step3";
import "./style.scss";

const BrowseAvailabilities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dateError, setDateError] = useState("");
  const account = useSelector((state) => state.account);
  const { filtered_locums, is_booking_request_sent, is_not_member_error, is_profile_incomplete_error } = useSelector((state) => state.bookings);
  const { locum_available_dates, locum_info, booked_dates } = useSelector((state) => state.user);
  const [dateSelectError, setDateSelectError] = useState("");
  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [infoAlertMsg, setInfoAlertMsg] = useState("");
  const [state, setState] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showProfileIncomplete, setShowProfileIncomplete] = useState(false);
  const [showNotAMemberError, setShowNotAMemberError] = useState(false);
  const [locumSelected, setLocumSelected] = useState(false);
  const [selectionMode, setSelectionMode] = useState('single');
  const [locumBookedDates, setLocumBookedDates] = useState([]);
  const [sortByFilter, setSortByFilter] = useState("");
  const [locumAvailableDates, setLocumAvailableDates] = useState([]);
  const [columnSize, setColumnSize] = useState(12);
  const [cordiantes] = useState({
    lat: "",
    lng: "",
  });

  const {
    date,
    from_date,
    to_date,
    active_step,
    selected_dates_save,
    selected_dates_show,
    locum_id,
    total_amount,
    locum_booked_dates,
  } = useSelector((state) => state.browseSteps);

  // * Get sorted locums
  useEffect(() => {
    if (sortByFilter && sortByFilter !== "") {
      dispatch(
        GetFilteredLocums({
          date: date,
          fromDate: from_date,
          toDate: to_date,
          sortBy: sortByFilter,
          cordiantes: cordiantes,
          user_token: account.user_token,
        })
      );
    }
  }, [sortByFilter]);

  useEffect(() => {
    switch (active_step) {
      case 0:
        setColumnSize(12)
        break;
      case 1:
        setColumnSize(16)
        dispatch(
          SetBrowseSliceSate([
            {
              bitToSet: "locum_id",
              value: null,
            },
          ])
        );
        break;
      case 2:
        setColumnSize(20)
        break;
      case 3:
        setColumnSize(10)
        break;
      default:
        break;
    }
  }, [active_step]);

  // * Check if profile incomplete error
  useEffect(() => {
    if (is_profile_incomplete_error) {
      dispatch(SetAvailabilitySliceBits({ bitToSet: "is_profile_incomplete_error", value: false }));
      setShowProfileIncomplete(true);
    }
  }, [is_profile_incomplete_error]);

  // * Check if not a member
  useEffect(() => {
    if (is_not_member_error) {
      dispatch(SetAvailabilitySliceBits({ bitToSet: "is_not_member_error", value: false }));

      setShowNotAMemberError(true);
    }
  }, [is_not_member_error]);

  // * Check if request sent success
  useEffect(() => {
    if (is_booking_request_sent && state) {
      setShowSuccessScreen(true);
      dispatch(SetAvailabilitySliceBits({ bitToSet: "is_booking_request_sent", value: false }));
      dispatch(ResetBrowseSteps());
    }
  }, [is_booking_request_sent]);

  // * Handle next click
  const handleNext = () => {
    if (active_step === 0) {
      if (!date) {
        if (!from_date || !to_date) {
          setDateError("Please select dates to continue");
          return;
        }
      }
      getFilteredLocums();
      setDateError("");
    }

    if (active_step === 1) {
      if (locum_id) {
        dispatch(GetFilteredLocumInfoAndDates({ locum_id: locum_id }));
        dispatch(
          SetBrowseSliceSate([
            {
              bitToSet: "selected_dates_save",
              value: [],
            },
            {
              bitToSet: "selected_dates_show",
              value: [],
            },
          ])
        );

        setStartDate(null);
        setEndDate(null);
        setSelectionMode('single');
      }
    }

    if (active_step === 2) {
      if (!selected_dates_save || selected_dates_save.length === 0) {
        setDateSelectError("Select dates to continue");
        return;
      } else {
        // Sort dates which we need to display
        dispatch(
          SetBrowseSliceSate([
            {
              bitToSet: "selected_dates_save",
              value: selected_dates_save
                .slice()
                .sort((a, b) => new Date(a).getTime() - new Date(b).getTime()),
            },
          ])
        );
      }
    }

    if (active_step > 2) {
      return;
    }

    dispatch(SetSteps(active_step + 1));
  };

  // * Handle back click
  const handleBack = () => {
    dispatch(SetSteps(active_step - 1));
  };

  // * Get filtered locums
  const getFilteredLocums = () => {
    dispatch(
      GetFilteredLocums({
        date: date,
        fromDate: from_date,
        toDate: to_date,
        sortBy: sortByFilter,
        cordiantes: cordiantes,
        user_token: account.user_token,
      })
    );
  };

  // * Send booking request
  const sendBookingRequest = () => {
    if (!account.role || !account.user_token) {
      setShowInfoAlert(true);
      setInfoAlertMsg("You must be logged in to send booking requests.");
      return;
    } else {
      if (account.role !== ROLES.practice) {
        setShowInfoAlert(true);
        setInfoAlertMsg(
          'Your account type must be "Practice" to send booking requests.'
        );
        return;
      }
    }
    if (
      selected_dates_save &&
      selected_dates_save.length > 0 &&
      total_amount > 0
    ) {
      dispatch(
        SendBookingRequest({
          locum_id: locum_id,
          token: account.user_token,
          selectedDates: selected_dates_save,
          totalAmount: total_amount,
        })
      );
      setState(true);
    }
  };

  // * Handle select locum
  const handleLocumSelect = (id) => {
    dispatch(
      SetBrowseSliceSate([
        {
          bitToSet: "locum_id",
          value: id,
        },
      ])
    );
    setLocumSelected(true);
  };

  // * When locum selected move to next step
  useEffect(() => {
    if (locum_id && locumSelected) {
      handleNext();
    }
  }, [locum_id]);

  // * Populate booked dates
  useEffect(() => {
    if (
      booked_dates &&
      booked_dates.length > 0
    ) {
      var bookedDatesArr = booked_dates.map((item) => new Date(item));
      setLocumBookedDates(bookedDatesArr);
      dispatch(
        SetBrowseSliceSate([
          {
            bitToSet: "locum_booked_dates",
            value: booked_dates,
          },
        ])
      );
    }
  }, [booked_dates]);

  // * Alow available dates booked dates
  useEffect(() => {
    if (locum_available_dates && locum_available_dates.length > 0) {
      var availableDatesArr = locum_available_dates.map((item) => new Date(item));
      setLocumAvailableDates(availableDatesArr);
    }
  }, [locum_available_dates]);

  // * Handle calender selection mode
  const handleSelectionMode = (val) => {
    setStartDate(null);
    setEndDate(null);
    setSelectionMode(val.toLowerCase());
  };

  // * Handle date change
  const handleDateChange = (date) => {
    var dateSave = FIX_TIMEZONE_OFFSET(date);
    dateSave = dayjs(dateSave).format("YYYY-MM-DD 00:00:00");
    var dateShow = date;
    const arraySave = [...selected_dates_save];
    const arrayShow = [...selected_dates_show];
    const index = findIndexDate(arraySave, dateSave);
    if (index >= 0) {
      arraySave.splice(index, 1);
    } else {
      arraySave.push(dateSave);
    }

    const indexShow = findIndexDate(arrayShow, dateShow);
    if (indexShow >= 0) {
      arrayShow.splice(indexShow, 1);
    } else {
      arrayShow.push(dateShow);
    }

    dispatch(SetDatesSave(arraySave));
    dispatch(SetDatesShow(arrayShow));
    setDateSelectError("");
  };

  // * Handle range select
  const handleRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (startDate && endDate) {
      const preArraySave = [...selected_dates_save];
      const preArrayShow = [...selected_dates_show];
      const newArrSave = getDates(startDate, endDate, preArraySave, true);
      const newArrShow = getDates(startDate, endDate, preArrayShow);
      dispatch(
        SetBrowseSliceSate([
          {
            bitToSet: "selected_dates_save",
            value: newArrSave,
          },
          {
            bitToSet: "selected_dates_show",
            value: newArrShow,
          },
        ])
      );
      setDateSelectError("");
    }
  }, [startDate, endDate]);

  // * Get dates array between two dates
  const getDates = (startDate, stopDate, datesArr, forSave = false) => {
    startDate = new Date(startDate);
    stopDate = new Date(stopDate);
    var newDatesArr = [...datesArr];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      const localnewDateForArr = new Date(currentDate);

      const index = findIndexDate(newDatesArr, localnewDateForArr);
      if (index >= 0) {
        newDatesArr.splice(index, 1);
      } else {
        if (locum_available_dates.includes(dayjs(localnewDateForArr).format("YYYY-MM-DD 00:00:00"))) {
          if (forSave) {
            var date_str = dayjs(localnewDateForArr).format("YYYY-MM-DD 00:00:00");
            if (!locum_booked_dates.includes(date_str)) {
              newDatesArr.push(date_str);
            }
          } else {
            newDatesArr.push(localnewDateForArr);
          }
        }
      }

      var dateAdded = new Date(currentDate.setDate(currentDate.getDate() + 1));
      currentDate = dateAdded;
    }
    return newDatesArr.length > 0
      ? newDatesArr.sort((date1, date2) => date1 - date2)
      : newDatesArr;
  };

  const findIndexDate = (dates, date) => {
    const dateTime = new Date(date).getTime();
    return dates.findIndex(
      (item) => item && new Date(item).getTime() === dateTime
    );
  };

  // * Calculate amount
  useEffect(() => {
    if (selected_dates_save && selected_dates_save.length > 0) {
      if (locum_info) {
        var totalAmount = 0;
        selected_dates_save.map((item) => {
          const d = new Date(item.toString());
          let day = d.getDay();
          if (day === 6) {
            totalAmount =
              parseInt(totalAmount) + parseInt(locum_info.charge_rate_sat);
          } else if (day === 0) {
            totalAmount =
              parseInt(totalAmount) + parseInt(locum_info.charge_rate_sun);
          } else {
            totalAmount =
              parseInt(totalAmount) + parseInt(locum_info.charge_rate);
          }
          return null;
        });
        dispatch(
          SetBrowseSliceSate([
            {
              bitToSet: "total_amount",
              value: totalAmount,
            },
          ])
        );
      }
    } else {
      dispatch(
        SetBrowseSliceSate([
          {
            bitToSet: "total_amount",
            value: 0,
          },
        ])
      );
    }
  }, [selected_dates_save]);

  return (
    <Row>
      <Col span={24}>
        <Row justify="center">
          <Col
            xs={24}
            sm={20}
            md={14}
            lg={columnSize}
            xl={columnSize}
            xxl={columnSize}
            className={
              active_step === 2
                ? "browse_locum_stepper max_width"
                : "browse_locum_stepper"
            }
          >
            {showSuccessScreen ? (
              <Result
                className="post_avail_success"
                status="success"
                title={`Congratulation! Your requested dates have been sent to ${locum_info.first_name}${locum_info.last_name ? "." + locum_info.last_name.charAt(0).toUpperCase() : ''} You will
                    get notification once ${locum_info.first_name}${locum_info.last_name ? "." + locum_info.last_name.charAt(0).toUpperCase() : ''} review your booking. You can
                    check/edit your bookings in your Account.`}
                subTitle="You can view your bookings in your Account."
                extra={[
                  <AntDesignButton
                    margin_x={[10, 10]}
                    margin_y={[10, 10]}
                    btn_label="Browse more availabilties"
                    handleClick={() => {
                      dispatch(ResetBrowseSteps());
                      setShowSuccessScreen(false);
                    }}
                  />,
                  <AntDesignButton
                    margin_x={[10, 10]}
                    width="200"
                    btn_label="My bookings"
                    handleClick={() => {
                      navigate(`/${ROLES[account.role]}/bookings`);
                    }}
                  />,
                ]}
              />
            ) : (
              <>
                {(() => {
                  switch (active_step) {
                    case 0:
                      return (
                        <Row>
                          <Col span={24}>
                            <h4>Select Dates</h4>
                            <Divider></Divider>
                            <DateFilters />
                            {dateError && dateError !== "" && (
                              <small className="tex text-danger">
                                {dateError}
                              </small>
                            )}
                          </Col>
                        </Row>
                      );
                    case 1:
                      return (
                        <Row>
                          <Col span={24}>
                            <h4>Select Locum</h4>
                            <Divider></Divider>
                            <Row style={{ marginBottom: "50px" }}>
                              <Col sm={24} md={10}>
                                <MuiDropdown
                                  label={"Filter Locums"}
                                  id={"filter"}
                                  name={"filter"}
                                  inputValue={sortByFilter}
                                  handleChange={(event) => {
                                    setSortByFilter(event.target.value);
                                    dispatch(
                                      SetBrowseSliceSate([
                                        {
                                          bitToSet: "sort_by",
                                          value: event.target.value,
                                        },
                                      ])
                                    );
                                  }}
                                  options={SORT_OPTIONS}
                                ></MuiDropdown>
                              </Col>
                            </Row>
                            {filtered_locums &&
                              filtered_locums.length === 0 ? (
                              <div className=" text-center row m-0 my-5">
                                <p className="display-inline">
                                  No Locums Found.
                                </p>
                              </div>
                            ) : (
                              <Row gutter={[10, 10]}>
                                {filtered_locums.map(
                                  (item, index) => {
                                    return (
                                      <Col
                                        xs={24}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={8}
                                        xxl={6}
                                      >
                                        <LocumCard
                                          key={index}
                                          calc_distance={item.calc_distance}
                                          distance_range={item.distance_range}
                                          handleLocumSelect={handleLocumSelect}
                                          locumName={item.user_name}
                                          locumImageUrl={item.profileImageName}
                                          locumId={item.locum_id}
                                          charge_rate={item.charge_rate}
                                          charge_rate_sat={item.charge_rate_sat}
                                          charge_rate_sun={item.charge_rate_sun}
                                          rating={item.avg_rating}
                                          review_count={item.review_count}
                                        ></LocumCard>
                                      </Col>
                                    );
                                  }
                                )}
                              </Row>
                            )}
                          </Col>
                        </Row>
                      );
                    case 2:
                      return (
                        <Row>
                          <Col span={24}>
                            <h4>Select Dates</h4>
                            <Divider></Divider>
                            <Row>
                              <Col span={24} className="calender_mode_selector">
                                <p>Selection Mode</p>
                                <Segmented
                                  size="large"
                                  options={['Single', 'Range']}
                                  onChange={(value) => { handleSelectionMode(value) }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24} className="react-datepicker-div">
                                <DatePicker
                                  inline
                                  selectsRange={selectionMode === 'range' ? true : undefined}
                                  monthsShown={4}
                                  highlightDates={selected_dates_show}
                                  onChange={(date) => selectionMode === 'range' ? handleRangeChange(date) : handleDateChange(date)}
                                  excludeDates={locumBookedDates}
                                  includeDates={locumAvailableDates}
                                  peekNextMonth={false}
                                  minDate={new Date()}
                                  startDate={
                                    startDate ? new Date(startDate) : null
                                  }
                                  endDate={endDate ? new Date(endDate) : null}
                                  disabledKeyboardNavigation
                                ></DatePicker>
                                <small className="text-danger mt-3">
                                  {dateSelectError}
                                </small>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      );
                    case 3:
                      return (
                        <Step3
                          role={account.role}
                          showInfoAlert={showInfoAlert}
                          infoAlertMsg={infoAlertMsg}
                          showProfileIncomplete={showProfileIncomplete}
                          selected_dates_save={selected_dates_save}
                          showNotAMemberError={showNotAMemberError}
                        ></Step3>
                      );
                    default:
                      return <></>;
                  }
                })()}
                <Divider></Divider>
                <Row justify="space-between" style={{ marginTop: "20px" }}>
                  <AntDesignButton
                    btn_label="Back"
                    disabled={active_step === 0}
                    handleClick={handleBack}
                  />

                  {(active_step === 0 || active_step === 2) && (
                    <AntDesignButton
                      htmlType="button"
                      handleClick={handleNext}
                      disabled={active_step > 3}
                      btn_label="Next"
                    />
                  )}
                  {active_step === 3 && (
                    <>
                      <AntDesignButton
                        btn_label="Send booking request"
                        variant="contained"
                        color="primary"
                        handleClick={sendBookingRequest}
                      />
                    </>
                  )}
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BrowseAvailabilities;
