import React, { useEffect } from "react";
import Factory from "assets/images/factory.jpg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Blog from "components/cards/Blog";
import Secure from "components/cards/Secure";
import Tasker from "components/cards/Tasker";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import MapTest from "components/googlemaps/MapTest";
import Contactus from 'pages/contactus/ContactUs';
import { ROLES } from "utilities/enumerations";
import { useSelector } from "react-redux";
import { UserReviewLookup } from "store/modules/userSlice";
import PracticeReviewModal from "components/modals/PracticeReviewModal";
import { GET_SITE_CONTENT } from "utilities/helper";
import { Container } from 'react-bootstrap'
import { Flex, Avatar, Dropdown as AntDropdown, Button } from "antd";
import "./style.scss";

const Home = () => {
  const dispatch = useDispatch();
  const content = useSelector((state) => state.content);
  const account = useSelector((state) => state.account);
  const [value, setValue] = React.useState("1");
  useEffect(() => {
    document.title = "LocumsOnly | Home";
    if (
      account.is_logged_in &&
      account.user_token &&
      (account.role === ROLES.locum || account.role === ROLES.practice)
    ) {
      dispatch(UserReviewLookup({ user_token: account.user_token }));
    }
  }, [account.is_logged_in, account.role, account.user_token, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="homePage">
      <PracticeReviewModal></PracticeReviewModal>

      {/* Call us */}
      <section className="locums-only">
        <Container fluid='lg'>
          <div
            className="hero_section"
            dangerouslySetInnerHTML={{
              __html: GET_SITE_CONTENT(
                content.site_content_all,
                "home",
                "home_intro_para"
              ),
            }}
          ></div>
          <div className="hero_section_buttons">
            <NavLink to="/post_availabilities">
              <Button type="primary" block shape="round">Post your availability as Locum (No Cost)</Button>
            </NavLink>
            <NavLink to="/browse_availabilities">
              <Button type="primary" block shape="round">Browse Locum's availability</Button>
            </NavLink>
          </div>


        </Container>
      </section>

      <section className="call-us">
        <Container fluid='lg'>
          <div
            dangerouslySetInnerHTML={{
              __html: GET_SITE_CONTENT(
                content.site_content_all,
                "home",
                "home_call_us"
              ),
            }}
          ></div>
        </Container>
      </section>

      {/* Articles Stories */}
      <section className="articlesstories">
        <Container fluid='lg'>
          <div className="row">
            <Blog Factory={Factory}></Blog>
            <Blog Factory={Factory}></Blog>
            <Blog Factory={Factory}></Blog>
          </div>
        </Container>
      </section>

      {/* Testimonials */}
      {/* <Swiper></Swiper> */}
      {/* Blog btn */}
      <section className="visit_blog">
        <Container fluid='lg'>
          <NavLink to="">
            <Button type="primary" shape="round">Visit Our Blog</Button>
          </NavLink>
        </Container>
      </section>

      {/* our-services */}
      <section className="our-services">
        <Container fluid='lg'>
          <h4 className="mb-5">Feel safe and secure on LocumsOnly</h4>
          <div className="row">
            <Secure></Secure>
            <Secure></Secure>
            <Secure></Secure>
          </div>
        </Container>
      </section>

      {/* Taskers */}
      <section className="">
        <Container fluid='lg'>
          <TabContext value={value}>
            <Box>
              <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Item One" value="1" />
                <Tab label="Item Two" value="2" />
                <Tab label="Item Three" value="3" />
              </Tabs>
            </Box>
            <TabPanel value="1">
              <Tasker name={"Alex"}></Tasker>
            </TabPanel>
            <TabPanel value="2">
              <Tasker name={"John"}></Tasker>
            </TabPanel>
            <TabPanel value="3">
              <Tasker name={"Samantha"}></Tasker>
            </TabPanel>
          </TabContext>
        </Container>
      </section>

      <section className="office_location">
        <Container fluid='lg'>
          <h5>Contact Us for a free Quote!</h5>
          <div className="row">
            <div className="col-sm-10 col-md-10 col-lg-6 home_map">
              <MapTest
                lat={33.6461432}
                lng={73.0523224}
                zoom={15}
                showGetDirection={true}
                fullscreenControl={false}
                mapTypeControl={false}
                skeletonBorderRadius={true}
              ></MapTest>
            </div>
            <div
              id="contact_us_sec"
              className="col-sm-12 col-md-12 col-lg-6 m-auto"
            >
              <Contactus isHomePage={true}></Contactus>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Home;
