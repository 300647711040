// import { TimePicker } from "antd";
import { TimePicker } from "antd";
import dayjs from "dayjs";

const TimeRange = ({ handleTimeRange, timeRange }) => {
  // Send calues back to locum profile edit
  const handleTimeRangeChange = (timeRangeArr, dayName) => {
    handleTimeRange(timeRangeArr, dayName);
  };

  return (
    <div className="time_range_main">
      {/* Monday */}
      <div className="time_range_day_group">
        <div className="day_name">
          <small>Monday</small>
        </div>
        <div className="day_time_range">
          <TimePicker.RangePicker
            format={"HH:mm"}
            needConfirm={false}
            value={
              timeRange.mon.start &&
              timeRange.mon.end && [
                dayjs(timeRange.mon.start, "HH:mm"),
                dayjs(timeRange.mon.end, "HH:mm"),
              ]
            }
            size="small"
            name="mon"
            minuteStep={30}
            use12Hours={false}
            onChange={(date, timeRangeArr) =>
              handleTimeRangeChange(timeRangeArr, "mon")
            }
          />
        </div>
      </div>
      {/* Tuesday */}
      <div className="time_range_day_group">
        <div className="day_name">
          <small>Tuesday</small>
        </div>
        <div className="day_time_range">
          <TimePicker.RangePicker
            format={"HH:mm"}
            needConfirm={false}
            value={
              timeRange.tue.start &&
              timeRange.tue.end && [
                dayjs(timeRange.tue.start, "HH:mm"),
                dayjs(timeRange.tue.end, "HH:mm"),
              ]
            }
            size="small"
            name="tue"
            minuteStep={30}
            use12Hours={false}
            onChange={(date, timeRangeArr) =>
              handleTimeRangeChange(timeRangeArr, "tue")
            }
          />
        </div>
      </div>
      {/* Wednesday */}
      <div className="time_range_day_group">
        <div className="day_name">
          <small>Wednesday</small>
        </div>
        <div className="day_time_range">
          <TimePicker.RangePicker
            format={"HH:mm"}
            needConfirm={false}
            value={
              timeRange.wed.start &&
              timeRange.wed.end && [
                dayjs(timeRange.wed.start, "HH:mm"),
                dayjs(timeRange.wed.end, "HH:mm"),
              ]
            }
            size="small"
            name="wed"
            minuteStep={30}
            use12Hours={false}
            onChange={(date, timeRangeArr) =>
              handleTimeRangeChange(timeRangeArr, "wed")
            }
          />
        </div>
      </div>
      {/* Thursday */}
      <div className="time_range_day_group">
        <div className="day_name">
          <small>Thursday</small>
        </div>
        <div className="day_time_range">
          <TimePicker.RangePicker
            format={"HH:mm"}
            needConfirm={false}
            value={
              timeRange.thu.start &&
              timeRange.thu.end && [
                dayjs(timeRange.thu.start, "HH:mm"),
                dayjs(timeRange.thu.end, "HH:mm"),
              ]
            }
            size="small"
            name="thu"
            minuteStep={30}
            use12Hours={false}
            onChange={(date, timeRangeArr) =>
              handleTimeRangeChange(timeRangeArr, "thu")
            }
          />
        </div>
      </div>
      {/* Friday */}
      <div className="time_range_day_group">
        <div className="day_name">
          <small>Friday</small>
        </div>
        <div className="day_time_range">
          <TimePicker.RangePicker
            format={"HH:mm"}
            needConfirm={false}
            value={
              timeRange.fri.start &&
              timeRange.fri.end && [
                dayjs(timeRange.fri.start, "HH:mm"),
                dayjs(timeRange.fri.end, "HH:mm"),
              ]
            }
            size="small"
            name="fri"
            minuteStep={30}
            use12Hours={false}
            onChange={(date, timeRangeArr) =>
              handleTimeRangeChange(timeRangeArr, "fri")
            }
          />
        </div>
      </div>
      {/* Saturday */}
      <div className="time_range_day_group">
        <div className="day_name">
          <small>Saturday</small>
        </div>
        <div className="day_time_range">
          <TimePicker.RangePicker
            format={"HH:mm"}
            needConfirm={false}
            value={
              timeRange.sat.start &&
              timeRange.sat.end && [
                dayjs(timeRange.sat.start, "HH:mm"),
                dayjs(timeRange.sat.end, "HH:mm"),
              ]
            }
            size="small"
            name="sat"
            minuteStep={30}
            use12Hours={false}
            onChange={(date, timeRangeArr) =>
              handleTimeRangeChange(timeRangeArr, "sat")
            }
          />
        </div>
      </div>
      {/* Sunday */}
      <div className="time_range_day_group">
        <div className="day_name">
          <small>Sunday</small>
        </div>
        <div className="day_time_range">
          <TimePicker.RangePicker
            format={"HH:mm"}
            needConfirm={false}
            value={
              timeRange.sun.start &&
              timeRange.sun.end && [
                dayjs(timeRange.sun.start, "HH:mm"),
                dayjs(timeRange.sun.end, "HH:mm"),
              ]
            }
            size="small"
            name="sun"
            minuteStep={30}
            use12Hours={false}
            onChange={(date, timeRangeArr) =>
              handleTimeRangeChange(timeRangeArr, "sun")
            }
          />
        </div>
      </div>
    </div>
  );
};
export default TimeRange;
