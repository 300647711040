import React from "react";
import Rating from "../rating/Rating";
import Profile from "../../assets/images/profile.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CAPITALIZE } from "../../utilities/helper";
import { Card, Col, Divider, Image, Row } from "antd";
import AntDesignButton from "../others/Button/AntDesignButton";

const LocumCard = ({
  calc_distance = 0,
  distance_range,
  locumImageUrl,
  rating,
  locumName,
  charge_rate,
  charge_rate_sat,
  charge_rate_sun,
  locumId,
  showProfileBtn = true,
  handleLocumSelect,
  review_count,
}) => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const handleReviewerProfileLink = (id) => {
    navigate(
      `/${account.role}/user_profile/${id}`,
      {
        state: {
          user_id: id,
        },
      },
      true
    );
  };

  const getRateString = (rate) => {
    if (rate) {
      return "$" + rate + " + GST";
    }
  };

  const handleLocumNameClick = (id) => {
    handleLocumSelect(id);
  };

  const handleReviewClick = (id) => {
    navigate(
      `/${account.role}/user_profile/${id}`,
      {
        state: {
          user_id: id,
          active_tab: "reviews",
        },
      },
      true
    );
  };

  return (
    <Row>
      <Col span={24}>
        <Card hoverable className=" locum_card m-auto">
          <Row>
            <div class="alert alert-dark w-100" role="alert">
              <small>Locum distance range : {distance_range} KM</small>
              <br></br>
              <small>
                Calculated distance :{" "}
                {calc_distance && calc_distance !== ""
                  ? parseFloat(calc_distance).toFixed(1) + " KM"
                  : 0}
              </small>
            </div>
          </Row>
          <Row justify="center" className="reviewer_profile_img">
            <Col className="img_container">
              {locumImageUrl != null && locumImageUrl !== "" ? (
                <Image
                  width={200}
                  src={
                    process.env.REACT_APP_API_PROFILE_IMAGES_URI + locumImageUrl
                  }
                  className="card-img-top"
                  alt="..."
                />
              ) : (
                <Image
                  width={200}
                  src={Profile}
                  className="card-img-top place_holder_img"
                  alt="..."
                />
              )}
            </Col>
          </Row>
          <Divider></Divider>
          <div className="">
            {showProfileBtn && (
              <p className="text-muted m-0">
                <button
                  type="button"
                  onClick={() => {
                    handleLocumNameClick(locumId);
                  }}
                  className="reviewer_name_btn"
                >
                  {/* {locumName} */}
                  Select dates
                </button>
              </p>
            )}
            <p className="m-0 mb-2">{CAPITALIZE(locumName)}</p>
            <Row
              justify="space-between"
              onClick={() => handleReviewClick(locumId)}
            >
              <Rating readOnly={true} initialValue={rating}></Rating>
              <p className="m-0">
                {review_count && parseInt(review_count) === 1
                  ? review_count + " rating"
                  : review_count + " ratings"}
              </p>
            </Row>
            <div className="locum_rates">
              <p>Rates-</p>
              <div className="rates">
                <p>Weekdays</p>
                <p>{getRateString(charge_rate)}</p>
              </div>
              <div className="rates">
                <p>Saturday</p>
                <p>{getRateString(charge_rate_sat)}</p>
              </div>
              <div className="rates">
                <p>Sunday</p>
                <p>{getRateString(charge_rate_sun)}</p>
              </div>
            </div>
            <div>
              <AntDesignButton
                block={true}
                margin_y={[10, 10]}
                btn_label="Show Profile"
                handleClick={() => {
                  handleReviewerProfileLink(locumId);
                }}
              />
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default LocumCard;
