import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const Step2 = ({ is_update, role }) => {
  const navigate = useNavigate();
  return (
    <Result
      className="post_avail_success"
      status="success"
      title="Congratulation! Your dates have been posted. You will get notifications once a Practice request a booking with you."
      subTitle="You can view/edit your bookings in your Account."
      extra={[
        is_update ? (
          <Button
            key="my_calender"
            type="primary"
            className=" btn_submit_primary"
            onClick={() => navigate(`/${role}/my_calender`)}
          >
            Go to My Calender
          </Button>
        ) : (
          <Button
            key="post_more_availabilities"
            type="primary"
            className=" btn_submit_primary"
            onClick={() => {
              window.location.reload(false);
            }}
          >
            Post more dates
          </Button>
        ),
        <Button
          key="bookings"
          type="primary"
          className=" btn_submit_primary"
          onClick={() => navigate(`/${role}/bookings`)}
        >
          Go to My Bookings
        </Button>,
      ]}
    />
  );
};

export default Step2;
