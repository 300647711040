import React, { useContext } from "react";
import {
  Chat,
  ChannelList,
  Channel,
  Window,
  ChannelHeader,
  MessageList,
  MessageInput,
  Thread,
} from "stream-chat-react";
import "./style.scss";
import "../../node_modules/stream-chat-react/dist/scss/v2/index.scss";
import IsProcessing from "../components/others/IsProcessing/IsProcessing";
import { useLocation } from "react-router-dom";
import { ROLES } from "utilities/enumerations";
import { GetStreamContext } from "providers/getStreamProvider";
import { useSelector } from 'react-redux'

const StreamChat = () => {

  const { client } = useContext(GetStreamContext);
  const location = useLocation();
  const { user_chat_id, role } = useSelector((state) => state.account);
  const { channel_id, is_loading } = useSelector((state) => state.chat);


  return (
    <>
      {!is_loading && client && client != null ? (
        <div className="userChat">
          <Chat client={client} theme={"messaging light"}>
            <ChannelList
              customActiveChannel={location?.state?.user_id ? channel_id : ''}
              showChannelSearch={role && role === ROLES.admin ? true : false}
              options={{
                watch: true,
              }}
              filters={{ members: { $in: [user_chat_id] } }}
            ></ChannelList>
            <Channel
            >
              <Window>
                <ChannelHeader></ChannelHeader>
                <MessageList></MessageList>
                <MessageInput focus={true}></MessageInput>
              </Window>
              <Thread />
            </Channel>
          </Chat>
        </div>
      ) : (
        <IsProcessing></IsProcessing>
      )}
    </>
  );
};

export default StreamChat;
