import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Label from "./Label/Label";

const MuiDropdown = ({
  label,
  inputValue,
  inputError,
  name,
  id,
  handleChange,
  options,
  bold,
  dropdownHeight = 300,
  isRequired,
  fullWidth = true,
}) => {
  return (
    <div>
      <Label label={label} isRequired={isRequired} bold={bold}></Label>
      <FormControl fullWidth={fullWidth}>
        <Select
          id={id}
          sx={{ height: "44px" }}
          variant="standard"
          error={inputError && inputError !== "" ? true : false}
          name={name}
          value={inputValue}
          onChange={(event) => handleChange(event)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{ PaperProps: { sx: { maxHeight: dropdownHeight } } }}
        >
          <MenuItem value="" selected>
            <em>None</em>
          </MenuItem>
          {options &&
            options.length > 0 &&
            options.map((ele, index) => {
              return (
                <MenuItem
                  value={ele.value ? ele.value : ele.name}
                  key={index}
                  disabled={ele.disabled ? true : false}
                >
                  {ele.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <small className="input_errors">{inputError}</small>
    </div>
  );
};

export default MuiDropdown;
